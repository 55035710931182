<template>
  <div class="authentication page-wrapper">
    <div class="title">登录</div>
    <div class="user-layout-login">
      <a-form-model :form="authentication" @submit.prevent="handleSubmit">
        <a-form-model-item prop="username">
          <a-input v-model="authentication.username" size="large" type="text">
            <a-icon
              slot="prefix"
              type="user"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-model-item>

        <a-form-model-item prop="password">
          <a-input-password v-model="authentication.password" size="large">
            <a-icon
              slot="prefix"
              type="lock"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input-password>
        </a-form-model-item>

        <a-form-model-item style="margin-top: 24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="loading"
            :disabled="loading"
            >登录</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

// import AccountService from "../../services/account";

export default {
  data() {
    return {
      loading: false,
      authentication: {},
    };
  },
  methods: {
    ...mapMutations(["setAuthentication", "setAccount"]),
    ...mapActions({
      addAuthentication: "addAuthentication",
    }),
    async handleSubmit() {
      this.loading = true;

      try {
        // const authentication = await window.axios({
        //   url: `/oauth/token`,
        //   method: "post",
        //   params: {
        //     username: this.authentication.username,
        //     password: this.authentication.password,
        //     grant_type: "password",
        //     client_id: "client_2",
        //     client_secret: "secret",
        //   },
        // });

        // console.log(authentication);
        // this.setAuthentication(authentication);

        // const accounts = await AccountService.getEntities();

        // if (accounts.length > 0) {
        //   this.setAccount(accounts[0]);
        // }

        const authenticationParam = {
          type: "USERNAME_PASSWORD",
          params: {
            username: this.authentication.username,
            password: this.authentication.password,
          },
        };

        await this.addAuthentication(authenticationParam);

        this.$router.push({ path: "/" });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.authentication {
  .title {
    padding: 16px 0;
    font-size: 24px;
  }
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 16px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
}
</style>
